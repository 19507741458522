.sites-button {
  background: $primary-color;
  font-size: 1.25rem;
  font-weight: bold;
  box-shadow: 0 -2px 0 rgba(0,0,0,0.2) inset;
  border-radius: 3px;
  border: none;
}

.button-group .button{
	margin-right: rem-calc(30);
}

.button-group.stacked-for-small .button{
	@include breakpoint(small only){
		flex: 0 0 75%;
		margin: 0 auto 30px;
	}
}

.clickBox{
    cursor: pointer;
}

.top-bar-button{
    .button{
        font-size: rem-calc(14);
        padding-left: 40px;
        
        &::before{
        	content: '';
        	display: inline-block;
        	margin-right: rem-calc(10);
        	background: url('../icons/icon-inner-arrow-circle.svg') no-repeat center center;
        	position: absolute;
        	top: 10px;
        	left: 12px;
        	width: 20px; 
        	height: 20px;
        }
    }
}

.button{
    @include breakpoint(small only){
        display: block;
        width: 75%;
        margin: rem-calc(30) auto;
        text-align: center;
    }
    
    -webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
    box-shadow: 7px 12px 24px 0px rgba(0, 0, 0, 0.25);
    border: none;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    letter-spacing: rem-calc(1);
    font-weight: 800;
    text-align: left;
    position: relative;
//    max-height: rem-calc(54);
    
    &.large{
        padding: rem-calc(23 30);
        font-size: rem-calc(12);
    }
    
    &.blue{
        background: -webkit-linear-gradient(-82deg, #39b4ed 25%, #1283d2 75%);
        background: -moz-linear-gradient(-82deg, #39b4ed 25%, #1283d2 75%);
        background: -o-linear-gradient(-82deg, #39b4ed 25%, #1283d2 75%);
        background: -ms-linear-gradient(-82deg, #39b4ed 25%, #1283d2 75%);
        background: linear-gradient(172deg, #39b4ed 25%, #1283d2 75%);
        
        &:hover{
        	background: -webkit-linear-gradient(-82deg, #114e7f 25%, #114e7f 75%);
        	background: -moz-linear-gradient(-82deg, #114e7f 25%, #114e7f 75%);
        	background: -o-linear-gradient(-82deg, #114e7f 25%, #114e7f 75%);
        	background: -ms-linear-gradient(-82deg, #114e7f 25%, #114e7f 75%);
        	background: linear-gradient(172deg, #114e7f 25%, #114e7f 75%);
        }
        
        &.ghost{
            background: transparent;
            border: solid rem-calc(2) $primary-color;
            color: $primary-color;
            box-shadow: none;
            padding: rem-calc(20 30 16);
        }
    }
    
    &.orange{
        background: -webkit-linear-gradient(-82deg, #ffba00 23%, #ff9201 48%, #ff6e02 77%);
        background: -moz-linear-gradient(-82deg, #ffba00 23%, #ff9201 48%, #ff6e02 77%);
        background: -o-linear-gradient(-82deg, #ffba00 23%, #ff9201 48%, #ff6e02 77%);
        background: -ms-linear-gradient(-82deg, #ffba00 23%, #ff9201 48%, #ff6e02 77%);
        background: linear-gradient(172deg, #ffba00 23%, #ff9201 48%, #ff6e02 77%);
    }
    
    &.green{
        background: -webkit-linear-gradient(-82deg, #bbf557 23%, #3fb041 77%);
        background: -moz-linear-gradient(-82deg, #bbf557 23%, #3fb041 77%);
        background: -o-linear-gradient(-82deg, #bbf557 23%, #3fb041 77%);
        background: -ms-linear-gradient(-82deg, #bbf557 23%, #3fb041 77%);
        background: linear-gradient(172deg, #bbf557 23%, #3fb041 77%);
    }
    
    &.purple{
        background: -webkit-linear-gradient(-82deg, #e5b2ca 23%, #7028e4 77%);
        background: -moz-linear-gradient(-82deg, #e5b2ca 23%, #7028e4 77%);
        background: -o-linear-gradient(-82deg, #e5b2ca 23%, #7028e4 77%);
        background: -ms-linear-gradient(-82deg, #e5b2ca 23%, #7028e4 77%);
        background: linear-gradient(172deg, #e5b2ca 23%, #7028e4 77%);
    }
    
    &.pink{
        background: -webkit-linear-gradient(-82deg, #fd5f93 25%, #f9407d 48%, #f52569 75%);
        background: -moz-linear-gradient(-82deg, #fd5f93 25%, #f9407d 48%, #f52569 75%);
        background: -o-linear-gradient(-82deg, #fd5f93 25%, #f9407d 48%, #f52569 75%);
        background: -ms-linear-gradient(-82deg, #fd5f93 25%, #f9407d 48%, #f52569 75%);
        background: linear-gradient(172deg, #fd5f93 25%, #f9407d 48%, #f52569 75%);
    }
    
    &.white{
        background: $white;
        color: $primary-color;
        
        &.ghost{
            background: transparent;
            border: solid rem-calc(2) $white;
            color: $white;
            box-shadow: none;
            padding: rem-calc(20 30 16);
            
            &:hover{
                background: $white;
                color: $primary-color;
            }
        }
    }
    
    &.facebook-blue{
    	background: #425D9A url('../icons/icon-facebook.svg') no-repeat center left 20px;
    	width: 100%;
    	margin: 0 auto;
    	display: block;
    	margin-bottom: rem-calc(20);
    	padding-left: rem-calc(50);
    }
    
    &.twitter-blue{
    	background: #27B2ED url('../icons/icon-twitter.svg') no-repeat center left 20px;
    	width: 100%;
    	margin: 0 auto;
    	display: block;
    	margin-bottom: rem-calc(20);
    	padding-left: rem-calc(50);
    }
    
    .icon{
        display: inline-block;
        position: relative;
        
        &.arrow-right{
            right: 0;
            margin-top: 0;
            margin-left: rem-calc(10);
            width: rem-calc(11);
            height: rem-calc(12);
            background: url('../icons/icon-arrow-right.svg') center center;
            background-size: contain;
            float: right;
        }
        
        &.play{
            right: 0;
            margin-top: -4px;
            margin-left: rem-calc(10);
            width: rem-calc(20);
            height: rem-calc(20);
            background: url('../icons/icon-play.svg') center center;
            background-size: contain;
            float: right;
        }

        &.inner-arrow-circle{
            left: 0;
            margin-right: rem-calc(10);
            margin-bottom: rem-calc(-3);
            width: rem-calc(20);
            height: rem-calc(20);
            background: url('../icons/icon-inner-arrow-circle.svg') center center;
            background-size: contain;
        }
    }
}

ul.social{
	list-style-type: none;
	float: right;
	
	li{
		display: inline-block;
		padding: rem-calc(0 10);
		
		
		.icon{
			&.social{
				content: '';
				display: block;
				width: 15px;
				height: 15px;
				
				&.facebook{
					background: transparent url('../icons/icon-facebook-footer.svg') no-repeat center center;
					background-size: contain;
				}
				&.twitter{
					background: transparent url('../icons/icon-twitter-footer.svg') no-repeat center center;
					background-size: contain;
				}
                &.instagram{
					background: transparent url('../icons/icon-instagram-footer.svg') no-repeat center center;
					background-size: contain;
				}
                &.youtube{
					background: transparent url('../icons/icon-youtube-footer.svg') no-repeat center center;
					background-size: contain;
				}
                &.google{
					background: transparent url('../icons/icon-google-footer.svg') no-repeat center center;
					background-size: contain;
				}
                &.linkedin{
					background: transparent url('../icons/icon-linkedin-footer.svg') no-repeat center center;
					background-size: contain;
				}
			}
		}
	}
}