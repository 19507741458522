.testimonials{
    text-align: center;
    overflow: hidden;
    
    img{
        text-align: center;
        margin: 0 auto rem-calc(30);
    }
    
    p{
        font-style: italic;
        font-size: rem-calc(24);
        margin-bottom: rem-calc(30);
        color: $black;
        width: 75%;
        margin: 20px auto;
    }
    
    .name,
    .location{
        font-style: normal;
        font-size: rem-calc(20);
        font-weight: bold;
        text-transform: uppercase;
    }
    
    .name{
        color: $black;
        margin-bottom: rem-calc(10);
    }
    
    .location{
        color: $orange;
        font-size: rem-calc(16);
    }
}