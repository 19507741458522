.signup-image{
	width: 100%;
	height: 100vh;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.signup-sidebar{
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: $white;
	text-align: center;
	padding: rem-calc(100);
	
	@include breakpoint(large){
		width: 50%;
	}
	
	@include breakpoint(xxlarge){
		width: 33.33%;
	}
	
	.login-box,
	.signup-box{
		position: absolute;
		width: 100%;
		padding: 100px 40px;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background: $white;
		
		@include breakpoint(medium){
			padding: 100px 250px;
		}
		
		@include breakpoint(large){
			padding: 100px;
		}
		
		.button{
			width: 100%;
			text-align: center;
		}
	}
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;
  &:before {
    content: '';
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 .5em;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    color: #818078;
    background-color: #fcfcfa;
  }
}

.page-template-page-signup,
.page-template-page-login{
	video{
		width: auto;
		height: 100vh;
	}
}

.video-overlay{
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, 0.5);
}