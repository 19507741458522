// Navigation breakpoints
.mobile-menu,
.site-title-bar {
  @include hide-for(large);
}

.desktop-menu,
.site-navigation .top-bar-left {
  @include show-for(large);
}

// Site title
.site-desktop-title a {
  font-weight: bold;
}

// Mobile menu
.mobile-menu {
  display: none; // prevents repaint caused by JS hiding menu onload
}

.off-canvas.is-transition-overlap{
	@include breakpoint(large){
		display: none;
	}
}

.mobile-menu .menu .is-active > a, .mobile-off-canvas-menu .menu .is-active > a{
	background-color: transparent !important;
}

&.position-right{
	-webkit-transform: translateX(350px);
	-ms-transform: translateX(350px);
	transform: translateX(350px);
}

.off-canvas {
  > ul.menu {
    height: auto;
    padding: 1rem;

    a {
      color: $white;
      font-weight: 600;
      font-size: rem-calc(15);
    }
  }

  .menu .active > a { background-color: #ccc; }

}

.off-canvas > ul.menu{
	padding: 0;
}

.off-canvas.is-transition-overlap{
	background: #f1f6f9;
	border-radius: 10px 0 0 10px;
	margin: 20px 0;
	height: 90%;
	width: 85%;
	z-index: 99999;
	
	@include breakpoint(medium){
		width: 50%;
	}
	
	.button{
		margin: 0 auto;
		display: block;
		width: 70%;
		text-align: center;
		margin-top: 20px;
		padding: 20px 0 25px 0;
		margin-bottom: 20px;
	}
	
//	.close-button{
//		position: absolute;
//		z-index: 999;
//		top: 0;
//		left: -40px;
//		width: 30px;
//		height: 30px;
//		display: block;
//	}
	
//	&::before{
//	    content: '';
//	    display: block;
//	    width: 100%;
//	    height: 80px;
//	    background: $white url('../images/miboodle-logo-blue.png') no-repeat center center;
//	    background-size: 50%;
//	}
	
	li{
		position: relative;
		
		a{
			color: $black;
			padding: rem-calc(16 0);
			text-transform: uppercase;
		}
		
		&.publisher,
		&.influencer,
		&.affiliate,
		&.cashback{
		    padding: rem-calc(10 0 10 90);
		    background: $white;
		    
		    &::before{
		        content: '';
		        display: inline-block;
		        position: absolute;
		        left: rem-calc(20);
		        top: rem-calc(10);
		        width: 58px;
		        height: 60px;
		        margin-right: rem-calc(15);
		    }
		    
		    &::after{
		        display: block;
		        color:  #838383;
		        font-size: rem-calc(12);
		        font-weight: 400;
		        text-transform: normal;
		    }
		}
		
		&.publisher{
			border-radius: 5px 0 0 0;
			
		    &:hover a{
		        color: $orange;
		    }
		    &::before{
		        background: url('../icons/icon-publisher.png') no-repeat center center;
		        background-size: contain;
		    }
		}
		
		&.influencer{
		    &:hover a{
		        color: $green;
		    }
		    &::before{
		        background: url('../icons/icon-influencer.png') no-repeat center center;
		        background-size: contain;
		    }
		}
		
		&.affiliate{
			padding-bottom: rem-calc(20);
			
		    &:hover a{
		        color: $purple;
		    }
		    &::before{
		        background: url('../icons/icon-affiliate.png') no-repeat center center;
		        background-size: contain;
		    }
		}
		
		&.cashback{
			padding-bottom: rem-calc(20);
			
		    &:hover a{
		        color: $pink;
		    }
		    &::before{
		        background: url('../icons/icon-cashback-pink.png') no-repeat center center;
		        background-size: contain;
		    }
		}
		
		&.publisher::after{
		    content: 'Build, Create & Publish your own sites.';
		}
		
		&.influencer::after{
		    content: 'Start monetising your social following today';
		}
		
		&.affiliate::after{
		    content: 'Invite your friends and get paid';
		}
		
		&.cashback::after{
		    content: 'Invite your friends and get paid';
		}
		
		&.tiers,
		&.mibo,
		&.kick-start,
		&.social{
		    background: #f1f6f9;
		    
		    a{
		        padding: 20px 0 20px 90px;;
		    }
		    
		    &:hover a{
		        color: $primary-color;
		    }
		    
		    &::before{
		        content: '';
		        display: inline-block;
		        position: absolute;
		        left: rem-calc(36);
		        top: 50%;
		        transform: translateY(-50%);
		        width: 25px;
		        height: 25px;
		        margin-right: rem-calc(15);
		    }
		}
		
		&.faq,
		&.login{
		    background: #f1f6f9;
		    
		    a{
		        padding: 20px 0 20px 90px;;
		    }
		    
		    &:hover a{
		        color: $primary-color;
		    }
		}
		
		&.tiers{
		    &::before{
		        background: url('../icons/icon-tiers.svg') no-repeat center center;
		        background-size: contain;
		    }
		}
		&.mibo{
		    &::before{
		        background: url('../icons/icon-mibo.svg') no-repeat center center;
		        background-size: contain;
		    }
		}
		&.kick-start{
		    &::before{
		        background: url('../icons/icon-kick-start.svg') no-repeat center center;
		        background-size: contain;
		    }
		}
		&.social{
			border-bottom: solid 2px $light-gray;
			border-radius: 0;
			
		    &::before{
		        background: url('../icons/icon-social.svg') no-repeat center center;
		        background-size: contain;
		    }
		}
		
		&:after{
			margin-top: -16px;
		}
	}
}

.title-bar-title {
  a {
    font-size: 1rem;
    color: #B9B9B9;
  }
}

.mobile-menu,
.mobile-off-canvas-menu {

  .menu .is-active > a {
    background-color: lighten($dark-nav-color, 5%);
  }
}

.logo{
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    display: block;
    background: url('../images/miboodle-logo.png') no-repeat center center;
    background-size: contain;
    width: rem-calc(200);
    height: rem-calc(40);
}

// Tablet and desktop menu
.site-header{
    -webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
    position: fixed;
    height: 100px;
    width: 100%;
    padding: 0;
    z-index: 9;
    
    &.smaller{
        height: rem-calc(60);
        background: $white;
        border-bottom: solid thin $light-gray;
        
        .logo{
            display: block;
            background: url('../images/miboodle-logo-blue.png') no-repeat center center;
            background-size: contain;
            width: rem-calc(140);
            height: rem-calc(30);
        }
        
        .menu-icon{        
            &::after{
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                -o-transition: all 0.5s ease-in-out;
                transition: all 0.5s ease-in-out;
                background: $black;
                box-shadow: 0 7px 0 $black, 0 14px 0 $black;
            }
        }
        
        .current-menu-parent > a{
        	color: $black !important;
        }
    }
}

.title-bar{
    background-color: transparent;
    width: 100%;
    padding: rem-calc(14 15);
}

.top-bar {
    -webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
    @include xy-grid-container;
    background: transparent;
    height: 100px;
    
    &.smaller {
        height: 60px;
        
        .logo{
            display: block;
            background: url('../images/miboodle-logo-blue.png') no-repeat center center;
            background-size: contain;
            width: rem-calc(140);
            height: rem-calc(30);
        }
        
        li{
            > a{
                color: $black;
                
                &:hover{
                    color: $primary-color;
                }
            }
        }
        
        .dropdown.menu > li.is-dropdown-submenu-parent > a::after{
            background: url('../icons/icon-arrow-down-black.svg') no-repeat center center;
        }
    }
    
    ul{
        background: transparent;
    }
    
  .top-bar-title a {
    font-size: 1rem;
    color: #B9B9B9;
    padding-left: 1rem;
    line-height: 1.8;
  }

    .menu a {
        color:  $white;
        font-size: rem-calc(14);
        font-weight: 700;
        line-height: rem-calc(30);
        text-align: center;
        text-transform: uppercase;
        letter-spacing: rem-calc(1);

        &:hover:not(.button) {
            background-color: transparent;
        }
    }
    
	.top-bar-right{
		-moz-transform-style: preserve-3d;
		-webkit-transform-style: preserve-3d;
		-o-transform-style: preserve-3d;
		transform-style: preserve-3d;
	}

  .menu .active > a { background-color: #ccc; }

    .menu>li:not(.menu-text)>a { 
        padding: 0 1rem;
        margin-right: rem-calc(20);
    }

    .dropdown.menu .submenu { border: 0; }
    .dropdown.menu .has-submenu.is-down-arrow a { padding-right: 1rem; }
    .dropdown.menu .has-submenu.is-down-arrow > a::after { border: 0; }
    
    .dropdown.menu > li.opens-left > .is-dropdown-submenu{
    	-webkit-box-shadow: 0px 0px 16px 0px rgba(153,153,153,1);
    	-moz-box-shadow: 0px 0px 16px 0px rgba(153,153,153,1);
    	box-shadow: 0px 0px 16px 0px rgba(153,153,153,1);
    	
        &::before{
            content: '';
            display: block;
            position: absolute;
            top: -6px;
            left: 50%;
            transform: translateY(-50%);
            margin: 0 0 0 -6px;
            width: 12px;
            height: 12px;
            transform: rotate(45deg);
            border-radius: 4px 0 0 0;
            background: #fff;
            box-shadow: -3px -3px 5px rgba(82,95,127,.04);
            will-change: transform;
            transition-property: transform;
            transition-duration: .25s;
        }
        
//        -webkit-transform: perspective(400) rotate3d(1, .10, 0, -90deg);
//        -webkit-transform-origin: 50% 0;
		-webkit-transition: all 0.5s ease-in-out;
		-moz-transition: all 0.5s ease-in-out;
		-o-transition: all 0.5s ease-in-out;
		transition: all 0.5s ease-in-out;
		display: none;
        opacity: 0;
        z-index: -999999;
        background: white;
        border-radius: 5px;
        padding: rem-calc(40 0 0);
        width: rem-calc(370);
        right: rem-calc(-143);
        
        a{
            color: $black;
            text-align: left;
            margin-right: 0;
            line-height: 100%;
            margin-bottom: rem-calc(5);
        }
        
        li{
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
            padding: 0 20px;
            
            &.publisher,
            &.influencer,
            &.affiliate,
            &.cashback{
                height: 80px;
                
                &::before{
                    content: '';
                    display: inline-block;
                    float: left;
                    width: 58px;
                    height: 60px;
                    margin-right: rem-calc(15);
                }
                
                &::after{
                    display: block;
                    color:  #838383;
                    font-size: rem-calc(14);
                    font-weight: 400;
                }
            }
            
            &.publisher{
                &:hover a{
                    color: $orange;
                }
                &::before{
                    background: url('../icons/icon-publisher.png') no-repeat center center;
                    background-size: contain;
                }
            }
            
            &.influencer{
                &:hover a{
                    color: $green;
                }
                &::before{
                    background: url('../icons/icon-influencer.png') no-repeat center center;
                    background-size: contain;
                }
            }
            
            &.affiliate{
                &:hover a{
                    color: $purple;
                }
                &::before{
                    background: url('../icons/icon-affiliate.png') no-repeat center center;
                    background-size: contain;
                }
            }
            
            &.cashback{
                &:hover a{
                    color: $pink;
                }
                &::before{
                    background: url('../icons/icon-cashback-pink.png') no-repeat center center;
                    background-size: contain;
                }
            }
            
            &.publisher::after{
                content: 'Build, Create & Publish your own sites.';
            }
            
            &.influencer::after{
                content: 'Start monetising your social following today';
            }
            
            &.affiliate::after{
                content: 'Invite your friends and get paid';
            }
            
            &.cashback::after{
                content: 'Invite your friends and get paid';
            }
            
            &.tiers,
            &.mibo,
            &.kick-start,
            &.social{
                background: #f1f6f9;
                padding: 15px 20px;
                
                a{
                    padding-top: 6px;
                    
                }
                
                &:hover a{
                    color: $primary-color;
                }
                
                &::before{
                    content: '';
                    display: inline-block;
                    float: left;
                    width: 25px;
                    height: 25px;
                    margin-right: rem-calc(15);
                }
            }
            
            &.tiers{
            	padding-top: rem-calc(30);
            	
                &::before{
                    background: url('../icons/icon-tiers.svg') no-repeat center center;
                    background-size: contain;
                }
            }
            &.mibo{
                &::before{
                    background: url('../icons/icon-mibo.svg') no-repeat center center;
                    background-size: contain;
                }
            }
            &.kick-start{
                &::before{
                    background: url('../icons/icon-kick-start.svg') no-repeat center center;
                    background-size: contain;
                }
            }
            &.social{
               padding-bottom: rem-calc(30);
               border-radius: 0 0 5px 5px;
               
                &::before{
                    background: url('../icons/icon-social.svg') no-repeat center center;
                    background-size: contain;
                }
            }
        }
    }
    
    .dropdown.menu > li.is-dropdown-submenu-parent{
        position: relative;
        
        &:hover > ul{
//			-webkit-transform: perspective(400) rotate3d(0, 0, 0, 0);
			display: block;
            opacity: 1;
            z-index: 2;
        }
        
        > a::after{
            display: inline-block;
            position: relative;
            right: 0;
            margin-left: rem-calc(20);
            width: rem-calc(11);
            height: rem-calc(11);
            background: transparent url('../icons/icon-arrow-down.svg') center center;
            background-size: contain;
            border: none;
        }
    }
}

.top-bar-button{
    margin-top: rem-calc(10);
}

.site-navigation {
  @include breakpoint(small only) {
    padding: 0; // prevents container visibility on small screens
  }
}

// WP post navigation
.post-navigation {
  @include clearfix;
}

.nav-previous {
  float:left;
}

.nav-next {
  float:right;
}
