.reasons{
    h3{
        color: $primary-color;
        font-weight: bold;
        font-size: rem-calc(28);
    }
    
    .icon,
    .content{
        margin-bottom: rem-calc(50);
    }
}