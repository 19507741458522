.section-title{
    margin-bottom: rem-calc(50);
}

.page-template-page-full-width{
    .full-row.faq-bg-shape{
        padding-top: 38rem;
        padding-bottom: 0;
        margin-top: -39.25rem;
        height: 1650px;
        
        @include breakpoint(large){
        	padding-top: 33rem;
        	height: 950px;
        }
    }
    
    .footer-container{
        border-top: none;
    }
}