.block{
    text-align: center;
    padding: rem-calc(35 35 100);
    margin-bottom: rem-calc(50);
    position: relative;
    
    @include breakpoint(xlarge){
    	padding: rem-calc(35 35 80);
    }
    
    h3{
        font-family: 'Lato';
        color: $black;
        text-transform: uppercase;
        max-width: 70%;
        margin: rem-calc(10) auto;
        letter-spacing: 2px;
        font-size: rem-calc(18);
    }
    
    p{
        color: $dark-gray;
    }
    
    &.white{
        background: $white;
    }
    
    img{
        margin-bottom: rem-calc(15);
    }
    
    .button{
    	position: absolute;
	    bottom: 20px;
	    left: 50%;
	    transform: translateX(-50%);
	    width: 56%;
    }
}