.footer-container {
  margin: 0 auto;
  border-top: 2px solid $white;
  margin-top: rem-calc(60);
}

.faq-header{
	float: left;
}

.faq-button{
	float: right;
	margin-right: rem-calc(40);
}

.footer {
  @include xy-grid-container;
  @include xy-grid;
  padding: rem-calc(60) 0;

    section {
        @include xy-cell(12);
        text-align: center;
        margin-bottom: rem-calc(50);
        
        @include breakpoint(large){
            @include xy-cell(auto);
            text-align: left;
            margin-bottom: 0;
        }
    }
    
    .widget_nav_menu{
        @include breakpoint(large){
            margin: 0;
        }
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li{
            display: block;
            width: 100%; 
            
            > a{
                padding: rem-calc(6 0);
                color: $white;
                
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    
    h6{
        font-weight: bold;
        color: $white;
        font-size: rem-calc(18);
    }
    
    [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea{
        background: transparent;
        border-color: $white;
        color: $white;
    }
    
    .menu .is-active > a{
        background: none;
    }
    
    .nsu-form input[type="submit"], .nsu-form button{
        @include breakpoint(small only){
            display: block;
            width: 75%;
            margin: rem-calc(30) auto;
            text-align: center;
        }

        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        background: $white url('../icons/icon-arrow-right-blue.svg') no-repeat center right 15px;
        box-shadow: 7px 12px 24px 0px rgba(0, 0, 0, 0.25);
        border: none;
        text-transform: uppercase;
        font-family: 'Raleway', sans-serif;
        letter-spacing: rem-calc(1);
        font-weight: 800;
        text-align: left;
        position: relative;
        padding: 15px 30px;
        color: $primary-color;
        cursor: pointer;
        width: 150px;
    }
}

.page-template-page-signup,
.page-template-page-login{
	.site-header,
	.full-row.faq-bg-shape{
		display: none;
	}
	
	.featured-hero{
		height: 100vh;
		
		.tagline{
			margin-left: 55% !important;
			
			@include breakpoint(xxlarge){
				width: 35%;
			}
		}
	}
}

.lower-footer{
	background: $black;
	color: $white;
	padding: rem-calc(20 0);
}

.mc4wp-success > p{
	color: lightgreen;
}