.full-row{
    &.light-blue-gray{
        background: $light-blue-gray;
    }
    &.border-bottom{
        border-bottom: solid rem-calc(1) $medium-gray;
    }
    &.ipad{
        background: none;
        
        @include breakpoint(large){
            background: url('../images/ipad.svg') no-repeat center right;
            background-size: 44%;
        }
    }
    &.become-involved-bg-shape{
        background: url('../images/become-involved-bg-shape.svg') no-repeat center center;
        background-size: cover;
        height: 3290px;
        margin-top: -640px;
        margin-bottom: -330px;
        padding-top: 680px;
        
        @include breakpoint(medium){
            height: 3060px;
            margin-bottom: -230px;
        }
        
        @include breakpoint(large){
            height: 2100px;
            margin-top: -760px;
            padding-top: 810px;
            margin-bottom: -130px;
        }
        
        @include breakpoint(xlarge){
            height: 2200px;
            margin-top: -730px;
            padding-top: 780px;
        }
        
        @include breakpoint(xxlarge){
            height: 2220px;
            margin-top: -790px;
            padding-top: 880px;
            margin-bottom: 0;
        }
    }
    
    &.faq-bg-shape{
        background: url('../images/faq-bg-shape.svg') no-repeat top center;
        padding-top: 600px;
	    margin-top: -500px;
        background-size: 1000%;
        
        @include breakpoint(medium){
            background-size: 480%;
            padding-top: 740px;
            padding-bottom: 0;
            margin-top: -500px;
        }
        
        @include breakpoint(large){
        	background-size: 200%;
            padding-top: 400px;
            margin-top: -180px;
        }
        
        @include breakpoint(xlarge){
        	background-size: 180%;
        	padding-top: 470px;
        	margin-top: -130px;
        }
        
        @include breakpoint(xxlarge){
            background-size: 150%;
            padding-top: 460px;
            margin-top: -200px;
        }
    }
}