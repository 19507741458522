.sidebar{
    .menu li{
        display: block;
        width: 100%;
        margin-bottom: rem-calc(15);
        
        a{
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
            width: 100%;
            background: $light-blue-gray url('../icons/icon-arrow-right-blue.svg') no-repeat center right 15px;
            padding: rem-calc(15 30);
            color: $dark-gray;
            
            &:hover{
                background: $primary-color url('../icons/icon-arrow-right.svg') no-repeat center right 15px;
                color: $white;
            }
        }
        
        &.is-active > a{
            background: $primary-color url('../icons/icon-arrow-right.svg') no-repeat center right 15px;
            color: $white;
        }
    }
    
    .nsu-submit{
    	display: block;
    	width: 100%;
    	-webkit-transition: all 0.5s ease-in-out;
    	-moz-transition: all 0.5s ease-in-out;
    	-o-transition: all 0.5s ease-in-out;
    	transition: all 0.5s ease-in-out;
    	box-shadow: 7px 12px 24px 0px rgba(0, 0, 0, 0.25);
    	border: none;
    	text-transform: uppercase;
    	font-family: 'Raleway', sans-serif;
    	letter-spacing: rem-calc(1);
    	font-weight: 800;
    	text-align: left;
    	position: relative;
    	padding: rem-calc(20 30);
    	font-size: rem-calc(12);
    	background: -webkit-linear-gradient(-82deg, #39b4ed 25%, #1283d2 75%);
    	background: -moz-linear-gradient(-82deg, #39b4ed 25%, #1283d2 75%);
    	background: -o-linear-gradient(-82deg, #39b4ed 25%, #1283d2 75%);
    	background: -ms-linear-gradient(-82deg, #39b4ed 25%, #1283d2 75%);
    	background: linear-gradient(172deg, #39b4ed 25%, #1283d2 75%);
    	color: $white;
    }
}

.page-template-page-faq,
.page-template-page-sidebar-left,
.single{
    .logo{
        background: url(../images/miboodle-logo-blue.svg) no-repeat center center;
    }
    
    .lower-footer{
    	.logo{
			background: url(../images/miboodle-logo.svg) no-repeat center center;
    	}
    }
    
    .top-bar .dropdown.menu a{
        color: $black;
    }
    
    .top-bar .dropdown.menu > li.is-dropdown-submenu-parent > a::after{
        background: transparent url(../icons/icon-arrow-down-black.svg) center center;
    }
    
    .menu-icon::after{
    	background: $black;
		box-shadow: 0 7px 0 $black, 0 14px 0 $black;
    }
}