.cards-container {

  @include xy-grid;

  .card {
    @include breakpoint(small) {
      @include xy-cell(12);

      .card-content {
        padding: 1rem;
      }

    }

    @include breakpoint(medium) {
      @include xy-cell(1 of 3, true, 0.7rem );

    }

  }
}

.card{
    background: $light-blue-gray;
    padding: rem-calc(50 50 100 50);
    position: relative;
    
    h3{
        text-transform: uppercase;
        font-size: rem-calc(20);
        font-weight: bold;
    }
    
    .button{
        width: 70%;
        margin: 0 auto;
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        
        @include breakpoint(xlarge){
        	width: 50%;
        }
    }
}
