.page-template-page-faq{
    .home-faq{
        h2{
            color: $black;
        }

        .tabs{
            background: none;
            border-right: solid 1px $medium-gray;
            color: $black;

            .tabs-title{
                text-align: left;

                > a{
                    color: $black;
                    display: inline-block;
                    width: 75%;
                    padding: 18px 20px;
                    font-size: rem-calc(16);
                    line-height: rem-calc(22);
                    position: relative;
                    font-weight: bold;

                    &:focus,
                    &[aria-selected='true'],
                    &:hover{
                        background: none;
                        color: $primary-color;
                        font-weight: bold;

                        &::after{
                            content: '';
                            display: block;
                            position: absolute;
                            top: 25px;
                            right: 0px;
                            width: 15px;
                            height: 28px;
                            background: url('../icons/icon-faq-arrow-right-gray.png') no-repeat center center;
                        }
                    }
                }

                span{
                    display: block;
                    float: left;
                    font-size: rem-calc(32);
                    font-weight: bold;
                    margin-right: rem-calc(10);
                    color: #0b2f4c;
                }
            }
        }

        .tabs-content{
            background: none;
            border: none;
            font-weight: normal;
            color: $dark-gray;
            text-align: left;
            
            p{
                font-size: rem-calc(18);
                line-height: rem-calc(36);
            }
        }
    }
}

.home-faq{
    h2,
    a{
        color: $white;
        font-weight: bold;
    }
    
    .tabs{
        background: none;
        border: none;
        color: $white;
        
        .tabs-title{
            text-align: left;
            
            > a{
                color: $white;
                display: inline-block;
                width: 75%;
                height: rem-calc(80);
                padding: 18px 20px;
                font-size: rem-calc(16);
                line-height: rem-calc(22);
                position: relative;
                
                &:focus,
                &[aria-selected='true'],
                &:hover{
                    background: none;
                    color: $white;
                    font-weight: bold;
                    
                    &::after{
                        content: '';
                        display: block;
                        position: absolute;
                        top: 25px;
                        right: 0px;
                        width: 15px;
                        height: 28px;
                        background: url('../icons/icon-faq-arrow-right.png') no-repeat center center;
                    }
                }
            }
            
            span{
                display: block;
                float: left;
                font-size: rem-calc(32);
                font-weight: bold;
                margin-right: rem-calc(10);
                color: $white;
            }
        }
    }
    
    .tabs-content{
        background: none;
        border: none;
        font-weight: bold;
        font-size: rem-calc(16);
        color: $white;
        text-align: left;
    }
    
    @include breakpoint(medium down){
	    .accordion-item{
	    	span{
	    	    display: block;
	    	    font-size: rem-calc(32);
	    	    font-weight: bold;
	    	    margin-bottom: rem-calc(10);
	    	    color: #0b2f4c;
	    	    position: relative;
	    	    z-index: 99;
	    	    margin-left: rem-calc(10);
	    	    margin-right: rem-calc(15);
	    	}
	    	
	    	&.is-active{
	    		.accordion-title{
	    			background: $primary-color;
	    			color: $white !important;
	    		}
	    	}
	    	
	    	.accordion-title{
				text-align: left;
	    	}
	    }
	}
}