// Page header

.home{
	.content-row{
		margin-top: -100px;
	}
}

.grid-x{
	padding: rem-calc(0 20);
}

video { 
	display: none;
	
	@include breakpoint(xlarge){
		display: block;
	    position: absolute;
		z-index: -100;
		top: 0;
		width: 100%;
	}
}


.front-hero {
	overflow-y: hidden;
	
  .intro {
    @include xy-grid-container;
    @include xy-grid;
  }

  @include breakpoint(small) {
	background: url('../images/home-bg-poster.jpg') top left;
    background-size: cover;
    padding: 5.25rem 0;
    margin: 0 0 rem-calc(32);
    height: 100vh;
    position: relative;
    text-align: center;
    
    p{
    	line-height: rem-calc(26);
    }
    
    a.home-banner-button{
    	margin: rem-calc(15) auto;
    	width: 90%;
    }
  }

  @include breakpoint(medium) {
	background: url('../images/home-bg-poster.jpg') bottom center;
    background-size: cover;
    background-position: center;
    height: rem-calc(685);
    margin: 0 0 rem-calc(72);
    text-align: left;
  }
  
  @include breakpoint(xlarge){
  	background: none;
  	background-color: rgba(0, 0, 0, 0.4);
  }

    .tagline {
        margin-left: 0 !important;
        margin-right: 0 !important;

        @include breakpoint(small) {
          width: 100%;
        }

        @include breakpoint(medium) {
          @include xy-cell(10);
          padding-top: 3rem;
          
          a.home-banner-button{
          	margin-right: rem-calc(15);
          }
        }
    }

    h1 {
        color: $white;
        font-weight: 400;
        font-family: 'Lato', sans-serif;
        
        @include breakpoint(small) {
          font-size: 2.2rem;
        }

        @include breakpoint(medium) {
          font-size: rem-calc(72);
        }
    }

    h2 {
        color: #fefefe;
        font-weight: 300;
        font-size: rem-calc(24);
        
        @include breakpoint(large){
            font-size: rem-calc(48);
            line-height: rem-calc(50);
        }
    }
    
    p{
        color:  $white;
        font-size: rem-calc(20);
        font-weight: 400;
        line-height: rem-calc(26);
        
        @include breakpoint(large){
            @include xy-cell(6);
            padding: 0;
            margin: rem-calc(40 0 40 0);
        }
    }
}

// Intro
.intro {
  @include xy-grid-container;
  @include xy-grid;

  .fp-intro {
    @include breakpoint(small) {
      @include xy-cell(12);
    }

    @include breakpoint(medium) {
      @include xy-cell(10);
      @include xy-cell-offset(1);

      h2 {
        font-weight: 300;
        margin-bottom: 1.5rem;
      }

      h4 {
        font-size: 1.125rem;
        line-height: 1.6;
        color: #777;
        margin-bottom: 2rem;
      }
    }
  }
}

// Section divider
.section-divider {
  @include xy-grid-container;
  @include xy-grid;

  hr {
    @include xy-cell(12);
    @extend .dotted;
    box-sizing: border-box;
  }
}

//Make Money Online
.miboodle{
    @include xy-grid-container;
    @include xy-grid;
    
    .make-money-online{
        @include xy-cell(12);
        
        @include breakpoint(large){
            @include xy-cell(6);
            
            .button{
                margin-right: rem-calc(30);
            }
        }
    }
}

.pad-row{
    padding: rem-calc(75 0);
}

// Kick Start Your Account
.contain{
    @include xy-grid-container;
    @include xy-grid;
    
    header {
    @include xy-cell(12);

    h2 {
      font-weight: 300;
    }

    h4 {
      font-size: 1.125rem;
      line-height: 1.6;
      color: #777;
    }
      
      span{
          font-weight: bold;
          color: $primary-color;
      }
  }
}

.kick-start{
    position: relative;
    z-index: 2;
}

.kick-start-row {
    text-align: center;

    .build-news,
    .link-social,
    .automate-content{
        @include breakpoint(small) {
            @include xy-cell(12);
        }

        @include breakpoint(medium) {
            @include xy-cell(4);
        }

        img {
            padding: 1.25rem;
            margin: 1rem auto 0 auto;
        }

        h3 {
            color: $black;
            font-weight: bold;
            font-size: rem-calc(20);
            text-transform: uppercase;
            letter-spacing: rem-calc(3);
        }

        p {
            font-size: 0.9375rem;
        }
    }

    .button{
        margin: 0 auto;
    }
}

.become-involved{
    color: $white;

    h2{
        color: $white;
        margin-bottom: rem-calc(50);
    }
    
    .grow-network{
        text-align: left;
    }
}

.border-bottom-white{
    border-bottom: solid 2px $white;
    margin-bottom: rem-calc(20);
}

.push{
    padding-top: rem-calc(120);
}

.home .content-row.pad-row{
	padding: 0;
}