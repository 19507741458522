.grid-container{
	overflow: hidden;
}

.accordion{
	background: transparent;
}

.accordion-item{
    margin-bottom: rem-calc(20);
    border: none;
}
.accordion-title{
    -webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
    color: $primary-color !important;
    background: $light-blue-gray;
    font-size: rem-calc(15);
    border: none;
    text-align: left;
    padding-right: 30px;
    
    &:hover,
    &.is-active{
        background: $primary-color;
        color: $white !important;
    }
}

.accordion-content{
	border: none;
	
	&:last-of-type{
		border-bottom: none;
	}
}

:last-child:not(.is-active) > .accordion-title{
	border-bottom: none;
}

.accordion-image-bg{
    @include breakpoint(small only){
        background: none !important;
    }
    @include breakpoint(medium only){
        background: none !important;
    }
}