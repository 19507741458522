.featured-hero {
  @include breakpoint(small) {
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    padding: rem-calc(100%) 0;
    margin: 0 0 rem-calc(32);
    height: 100vh;
    position: relative;
  }

  @include breakpoint(medium) {
    background-position: center center;
    height: rem-calc(500);
    margin: 0;
    text-align: left;
  }
    
    .intro {
        @include xy-grid-container;
        @include xy-grid;
    }

    .tagline {
        margin-left: 0 !important;
        margin-right: 0 !important;

        @include breakpoint(small) {
          @include xy-cell(12);
        }

        @include breakpoint(medium) {
          @include xy-cell(10);
          padding-top: 5.8rem;
        }
    }

    h1 {
        font-family: 'Lato', sans-serif;
        color:  $white;
        font-weight: 400;
        font-size: rem-calc(60);
        line-height: 50px;
        transform: scaleY(1.0037);
        letter-spacing: 1px;
        margin-bottom: rem-calc(20);
    }

    h2 {
        font-family: Raleway;
        color:  $white;
        font-size: rem-calc(30);/* Approximation due to font substitution */
        line-height: 40px;/* Approximation due to font substitution */
        text-align: left;
        transform: scaleY(1.0011);/* width and height properties ommitted due to transform */
    }

    p{
        color:  $white;
        font-size: rem-calc(20);
        font-weight: 400;
        line-height: rem-calc(36);

        @include breakpoint(large){
            padding: 0;
            margin: rem-calc(40 0 40 0);
        }
    }
}
