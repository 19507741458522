.single,
.blog{
    .main-content{
        header{
            text-align: center;
        }
    }
    
    h2.entry-title > a{
    	color: $black;
    	font-weight: bold;
    	
    	&:hover{
    		color: $primary-color;
    	}
    }
    
    header > img{
    	margin-bottom: rem-calc(40);
    }
    
    .sidebar{
    	h6{
    		text-transform: uppercase;
    		font-weight: bold;
    		text-align: center;
    		margin-bottom: rem-calc(20);
    	}
    	
    	.widget ul{
    		list-style-type: none;
    		margin: 0;
    		margin-bottom: rem-calc(40);
    		
    		li{
    			display: block;
    			margin-bottom: rem-calc(5);
    			
    			a{
    				-webkit-transition: all 0.5s ease-in-out;
    				-moz-transition: all 0.5s ease-in-out;
    				-o-transition: all 0.5s ease-in-out;
    				transition: all 0.5s ease-in-out;
    				text-align: center;
    				background: $light-blue-gray;
    				color: $primary-color;
    				font-weight: bold;
    				text-transform: uppercase;
    				display: block;
    				width: 100%;
    				padding: rem-calc(10);
    				
    				&:hover{
    					background: $primary-color;
    					color: $white;
    				}
    			}
    		}
    	}
    }
}

.single{
	.article-button{
		display: none;
	}
}

.blog{
	.entry-content{
		text-align: center;
	}
	
	article{
		margin-bottom: rem-calc(50);
	}
}

.post-categories{
	list-style-type: none;
}